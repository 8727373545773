import clsx from 'clsx';
import {useRef, useState} from 'react';
import {PopupModal} from 'react-calendly';
import {type ButtonProps, Button} from '#/app/components/Button.jsx';

type TalkToUsButtonProps = {
  children?: React.ReactNode;
} & Partial<ButtonProps>;

export const TalkToUsButton = (props: TalkToUsButtonProps) => {
  const {children = 'Talk to Us'} = props;
  const buttonProps: ButtonProps = {
    appearance: 'outline',
    children,
    className: `${props.className} _cta`,
    color: 'gray',
    size: 'lg',
    target: '_self',
    ...props,
  };

  const url = 'https://calendly.com/ufleet/demo';
  const prefill = {};
  const pageSettings = {};
  const utm = {};

  if (props.target === '_blank') {
    buttonProps.href = url;
  }

  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  const openPopup = () => {
    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div
        className={clsx(
          ' inset-0 z-[100] flex items-center justify-center overflow-x-hidden bg-[#57575799] ',
          isOpen ? 'show-popup active fixed flex h-screen w-screen' : 'hide-popup',
        )}
        onClick={closePopup}
        ref={ref}
        style={{margin: 0}}
      ></div>
      <PopupModal
        onModalClose={closePopup}
        open={isOpen}
        pageSettings={pageSettings}
        prefill={prefill}
        rootElement={ref.current as HTMLDivElement}
        url={url}
        utm={utm}
      />
      <Button onClick={openPopup} {...buttonProps}>
        {children}
      </Button>
    </>
  );
};
